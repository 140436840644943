.container-detail {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	min-height: 100dvh;
	height: auto;
	padding: 10px;
	box-sizing: border-box;
	height: auto;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1003%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(245%2c 236%2c 238%2c 1)'%3e%3c/rect%3e%3cpath d='M1440 0L1118.41 0L1440 272.07z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1118.41 0L1440 272.07L1440 280.58L1065.46 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M1065.46 0L1440 280.58L1440 415.27L985.61 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M985.61 0L1440 415.27L1440 479.58L625.37 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L588.14 560L0 376.81z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 376.81L588.14 560L650.55 560L0 359.78z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 359.78L650.55 560L987.52 560L0 263.91999999999996z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 263.92L987.52 560L1078.92 560L0 161.92000000000002z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1003'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
	background-size: cover;
	padding-top: 64px;
}

.sub-container {
	display: flex;
	justify-content: center;
	align-self: center;
	width: 100%;
}

.title-detail {
	font-size: 3rem;
	letter-spacing: 1px;
	font-weight: 900;
	color: #ffa5b8;
}

.description-detail {
	font-size: 1.2rem;
	letter-spacing: 1px;
	font-weight: 300;
	text-align: justify;
}

.picture-container {
	width: 40%;
}

.content-container {
	align-self: center;
	padding: 10px;
	width: 40%;
}

@media (max-width: 768px) {
	.container-detail {
		padding: 0px;
	}

	.sub-container {
		flex-direction: column;
	}

	.picture-container {
		align-self: center;

		width: 90%;
	}

	.content-container {
		align-self: center;
		width: 90%;
	}
}
