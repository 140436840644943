.card {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	width: 100%;
	height: 400px;
	min-height: 100%;
	gap: 20px;
	box-sizing: border-box;
}

.content-card {
	width: 60%;
	height: 100%;
	overflow: hidden;
	text-align: left;
}

.pic-container {
	width: 40%;
	overflow: hidden;
}

.picture {
	width: 80%;
}

.title-card {
	font-size: 2rem;
	font-weight: 900;
	margin-left: 20px;
}

.description {
	font-size: 1.5rem;
	font-weight: 300;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (max-width: 768px) {
	.card {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0px;
		height: auto;
	}
	.content-card {
		flex-direction: column;
		width: 100%;
		height: auto;
		text-align: center;
	}
	.pic-container {
		width: 100%;
		order: -1;
	}
	.picture {
		width: 50%;
	}
	.title-card {
		margin-left: 0;
		font-size: 1.5rem;
	}
	.description {
		margin-left: 0;
		text-align: justify;
		font-size: 1rem;
	}
}
