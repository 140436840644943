.service-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: auto;
	min-height: 100dvh;
}

.description {
	margin: 20px;
}

.service-subContainer {
	width: 80%;
	background-color: transparent;
	border-radius: 20px;
	height: auto;
	align-self: center;
}

.tabs {
	width: 100%;
	display: flex;
	gap: 5px;
	background-color: transparent;
	color: white;
	justify-content: space-between;
}

.tab {
	background-color: #ffa5b8;
	height: 80px;
	width: 33%;
	border-start-start-radius: 20px;
	border-start-end-radius: 20px;
	border: 2px solid #000;
	border-bottom: none;
	transition: transform 0.3s ease;
}

.tab:hover {
	cursor: pointer;
	transform: scaleY(1.05);
}

.content {
	width: auto;
	height: auto;
	min-height: 430px;
	background-color: white;
	border: 2px solid #000;
	border-top: none;
	border-end-end-radius: 20px;
	border-end-start-radius: 20px;
	padding-block: 20px;
}

.icon {
	width: 30px;
}

.tab-name {
	display: flex;
	gap: 10px;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	height: 100%;
}

@media screen and (max-width: 768px) {
	.service-container {
		height: auto;
		padding: 10px;
	}

	.content {
		width: 100%;
	}

	.service-subContainer {
		width: 100%;
	}
	.tabs {
		flex-direction: column;
	}
	.tab {
		width: 100%;
	}
	.tab-name {
		font-size: 20px;
	}
}
