.card-blog {
	max-width: 350px;
	height: auto;
	border-radius: 0.5rem;
	background-color: #fff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	border: 1px solid transparent;
}

.card-blog a {
	text-decoration: none;
}

.content-blog {
	padding: 1.1rem;
}

.image {
	object-fit: cover;
	width: 100%;
	height: 150px;
	background-color: white;
	overflow: hidden;
}

.picture-banner {
	width: 100%;
	height: 100%;
	object-fit: cover;
	height: auto;
}

.title-blog {
	color: #111827;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 600;
}

.desc {
	margin-top: 0.5rem;
	color: #6b7280;
	font-size: 0.875rem;
	line-height: 1.25rem;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.action {
	display: inline-flex;
	margin-top: 1rem;
	color: #ffffff;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 500;
	align-items: center;
	gap: 0.25rem;
	background-color: #286075;
	padding: 4px 8px;
	border-radius: 4px;
	width: 100%;

	justify-content: center;
}

.action span {
	transition: 0.3s ease;
}

.action:hover span {
	transform: translateX(4px);
	text-align: center;
}

/* BLOG LIST */

.blog-list {
	position: relative;
	display: flex;
	padding: 20px;
}

.picture-list {
	width: 100%;
}

.container-row {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.container-row > * {
	flex: 1 1 calc(30% - 1rem);
}

.card-blog:hover {
	cursor: pointer;
}

@media screen and (max-width: 768px) {
	.blog-list {
		flex-direction: column-reverse;
		display: flex;
	}

	.left-side {
		order: 1;
	}

	.container-row {
		order: -1;
	}
}
