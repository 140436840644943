.btn {
	display: inline-block;
	padding: 0.6rem 1.4rem;
	font-size: 16px;
	font-weight: 700;
	color: black;
	border: 3px solid #ffa5b8;
	cursor: pointer;
	position: relative;
	background-color: transparent;
	text-decoration: none;
	overflow: hidden;
	z-index: 1;
	font-family: inherit;
	border-radius: 20px;
}

.btn::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #ffa5b8;
	transform: translateX(-100%);
	transition: all 0.3s;
	z-index: -1;
}

.btn:hover::before {
	transform: translateX(0);
}

.btn-secondary {
	display: inline-block;
	padding: 0.6rem 1.4rem;
	font-size: 16px;
	font-weight: 700;
	color: black;
	border: 3px solid #286075;
	cursor: pointer;
	position: relative;
	background-color: transparent;
	text-decoration: none;
	overflow: hidden;
	z-index: 1;
	font-family: inherit;
	border-radius: 20px;
	transition: color 0.3s ease;
}

.btn-secondary::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #286075;
	transform: translateX(-100%);
	transition: all 0.3s;
	z-index: -1;
}

.btn-secondary:hover::before {
	transform: translateX(0);
}

.btn-secondary:hover {
	color: white;
}
