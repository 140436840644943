.table {
	margin-top: 30px;
	border: 1px solid white;
}

.rowItemText {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	font-family: Arial, Helvetica, sans-serif;
}

.columnHeader {
	display: flex;
	background-color: #286075;
	color: white;
	font-size: 1rem;
	font-family: "Roboto Slab", serif;
}

.listItems {
	padding: 10px;
	padding-right: 0px;
	width: 100%;
}

.item {
	border-radius: 10px;
}

.itemActive {
	background-color: #ffa5b8;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
	margin: 0;
	padding: 0;
}
